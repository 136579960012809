"use client";

import { Container } from "#ui/container";
import { Main } from "#ui/main";
import Link from "next/link";

export default function NotFound() {
  return (
    <Main>
      <Container>
        <h2>Not Found</h2>
        <p>Could not find requested resource</p>
        <Link href="/">Return Home</Link>
      </Container>
    </Main>
  );
}
