import { forwardRef } from "react";
import { containerVariants, type ContainerVariants } from "./container.styles";

export type ContainerRef = React.ElementRef<"div">;

export interface ContainerProps
  extends React.ComponentPropsWithoutRef<"div">,
    ContainerVariants {}

const Container = forwardRef<ContainerRef, ContainerProps>(
  ({ className, ...props }, ref) => {
    return (
      <div {...props} className={containerVariants({ className })} ref={ref} />
    );
  },
);

Container.displayName = "Container";

export { Container };
