import { defineConfig, type VariantProps } from "cva";
import { twMerge } from "./tailwind.utils";

// -----------------------------------------------------------------------------

export type { VariantProps };

export const { cva, cx, compose } = defineConfig({
  hooks: {
    onComplete: (className) => twMerge(className),
  },
});

// -----------------------------------------------------------------------------

export function formatAriaInvalid(
  ariaInvalid: boolean | "true" | "false" | "grammar" | "spelling" | undefined,
) {
  if (
    ariaInvalid === true ||
    ariaInvalid === "true" ||
    ariaInvalid === "grammar" ||
    ariaInvalid === "spelling"
  ) {
    return true;
  }
  if (ariaInvalid === false || ariaInvalid === "false") {
    return false;
  }
  return undefined;
}

export function formatAriaPressed(
  ariaPressed: boolean | "true" | "false" | "mixed" | undefined,
) {
  if (
    ariaPressed === true ||
    ariaPressed === "true" ||
    ariaPressed === "mixed"
  ) {
    return true;
  }
  if (ariaPressed === false || ariaPressed === "false") {
    return false;
  }
  return undefined;
}
