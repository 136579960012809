import { forwardRef } from "react";
import { mainVariants, type MainVariants } from "./main.styles";

export type MainRef = React.ElementRef<"main">;

export interface MainProps
  extends React.ComponentPropsWithoutRef<"main">,
    MainVariants {}

const Main = forwardRef<MainRef, MainProps>(({ className, ...props }, ref) => {
  return <main {...props} className={mainVariants({ className })} ref={ref} />;
});

Main.displayName = "Main";

export { Main };
